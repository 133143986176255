import React, { useState } from 'react';
import 'normalize.css';
import { Link } from 'gatsby';
import { RxCross1 } from 'react-icons/rx';
import { AiOutlineMenu } from 'react-icons/ai';
import { NavbarStyle } from '../styles/NavbarStyles';

// import NavbarStyle from '../styles/NavbarStyles';

export default function NavMobil({
  data,
  pageContext,
  navOpen,
  setNavOpen,
  desktop,
  appcontainer,
}) {
  const [menuOpen, setMenuOpen] = useState('');
  const handleClick = (e) => {
    e.preventDefault();
    appcontainer.current.scrollTo(0, 0);
    setNavOpen(!navOpen);
    window.scrollTo(0, 0);
  };

  const handleClickClose = (e) => {
    setNavOpen(false);
    appcontainer.current.scrollTo(0, 0);
  };

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const uniqueArray = (nav) => {
    let categories = [];
    categories = nav.nodes.map((project) => project.categories);
    return (categories = [...new Set(categories)]);
  };

  return (
    <NavbarStyle open={navOpen} onClick={(e) => handleClick(e)}>
      <div className="left">
        <div id="info">
          <AiOutlineMenu className="menu" />
          <p>Anneleen Bertels</p>
        </div>

        {navOpen &&
          uniqueArray(data.navInfo).map((title) => (
            <Link
              key={title}
              className="mobil-filter"
              to={`/${slugify(title)}`}
            >
              <p>{title}</p>
            </Link>
          ))}
      </div>

      <div className="center">
        {navOpen && (
          <p id="studio" href="#">
            Studio
          </p>
        )}

        {pageContext.categorie && pageContext.section === 'studio' && !navOpen && (
          <Link className="" to="/">
            <p className="categorie">
              <RxCross1 className="cross" />
              {pageContext.categorie}
            </p>
          </Link>
        )}

        {navOpen &&
          uniqueArray(data.navStudio).map((title) => (
            <Link
              key={title}
              className={
                pageContext.categorie === title
                  ? 'selected-categorie mobil-filter'
                  : 'mobil-filter'
              }
              to={`/${slugify(title)}`}
            >
              <p>
                {pageContext.categorie === title && (
                  <RxCross1 className="cross" />
                )}
                {title}
              </p>
            </Link>
          ))}
      </div>

      <div className="right">
        {navOpen && (
          <p id="labo" href="#">
            Laboratory
          </p>
        )}

        {pageContext.categorie && pageContext.section === 'labo' && !navOpen && (
          <Link className="" to="/">
            <p className="categorie">
              <RxCross1 className="cross" />
              {pageContext.categorie}
            </p>
          </Link>
        )}
        {navOpen &&
          uniqueArray(data.navLabo).map((title) => (
            <Link
              key={title}
              className={
                pageContext.categorie === title
                  ? 'selected-categorie mobil-filter'
                  : 'mobil-filter'
              }
              to={`/${slugify(title)}`}
            >
              <p>
                {pageContext.categorie === title && (
                  <RxCross1 className="cross" />
                )}
                {title}
              </p>
            </Link>
          ))}
      </div>
    </NavbarStyle>
  );
}
