import styled from 'styled-components';

export const SingleProjectStyle = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;

  .title {
    text-decoration: underline;
    position: fixed;
    top: 5vh;
    width: 100%;
    z-index: 5;
  }

  &.center {
    align-items: center;
    .title {
      right: 0px;
    }
  }

  &.right {
    align-items: flex-end;
    .title {
      right: 20px;
    }
  }

  &.left {
    align-items: flex-start;
    .title {
      left: 20px;
    }
  }
  .gatsby-image-wrapper {
    width: 50%;
  }

  .text {
    margin: 10vh 0;
    width: 50%;
     p {
      padding-bottom: 20px;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  a {
    mix-blend-mode: inherit;
  }

  @media (max-width: 900px) {
    margin-top: 20px;
    display: block;
    .text {
      width: 100%;
    }

    .gatsby-image-wrapper {
      width: 100%;
    }

    &.right {
      align-items: flex-start;
      .title {
        right: 20px;
        width: calc(100% - 40px);
      }
    }

    &.center {
      align-items: flex-start;
      .title {
        right: -20px;
      }
    }

    .title {
      top: 10vh;
    }

    .filter {
      position: fixed;
      top: 7.3vh;
    }
  }
`;
