import React, { useState } from 'react';
import 'normalize.css';
import { Link } from 'gatsby';
import { RxCross1 } from 'react-icons/rx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SanityBlockContent from '@sanity/block-content-to-react';
import Img from 'gatsby-image';
import { SingleProjectStyle } from '../styles/SingleProjectStyle';

// import SingleProjectStyle from '../styles/SingleProjectStyles';

export default function SingleProject({ project, position, desktop }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log(desktop);

  return (
    <SingleProjectStyle className={position}>
      {!desktop && position === 'center' && <p className="filter ">Studio</p>}
      {!desktop && position === 'right' && (
        <p className="filter ">Laboratory</p>
      )}
      <Link to="/">
        <p className="title">
          <RxCross1 className="cross" />
          {project.date && `${project.date}, `}
          {project.project && `${project.project}, `}
          {project.categories && `${project.categories}`}
        </p>
      </Link>

      <SanityBlockContent
        className="text"
        blocks={project._rawText}
        projectId="ft52ooxt"
        dataset="production"
      />
      {desktop &&
        project.images &&
        project.images.map((image) => (
          <Img fluid={image.asset.fluid} alt={image.alt} />
        ))}

      {!desktop && (
        <Slider {...settings}>
          {project.images &&
            project.images.map((image) => (
              <Img fluid={image.asset.fluid} alt={image.alt} />
            ))}
        </Slider>
      )}
    </SingleProjectStyle>
  );
}
