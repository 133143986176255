import styled from 'styled-components';

export const NavbarStyle = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  a {
    mix-blend-mode: ${(props) => (props.open ? 'inherit' : 'color-burn')};
  }

  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.open ? 'white' : 'none')};
  height: ${(props) => (props.open ? '100vh' : '20px')};
  width: 100%;
  position: sticky;

  top: ${(props) => (props.open ? '-20px' : '0')};
  /* padding-top: ${(props) => (props.open ? '20px' : '0')}; */
  cursor: pointer;

  z-index: 10;

  .categorie {
    text-decoration: underline;
    position: fixed;
    top: 5vh;
    width: calc(100% / 3);
    z-index: 5;
  }

  .selected-categorie {
    text-decoration: underline;
  }

  .right {
    .categorie {
      right: 20px;
    }
  }

  /* &:hover {
    background-color: #f9f9f9;
  } */
  div {
    width: calc(100% / 3);
  }
  @media (max-width: 900px) {
    div {
      width: 100%;
    }
    display: block;
    .categorie {
      top: 8vh;
      width: 100%;
    }

    .mobil-filter {
      padding-left: 62px;
      width: 100%;
      display: inline-block;
    }
    #info {
      display: flex;
      justify-content: initial;
      align-items: flex-end;
    }

    #studio,
    #labo {
      margin-top: 20px;
      padding-left: 42px;
    }

    .right {
      .categorie {
        right: -20px;
      }
    }

    .menu {
      width: 12%;
      height: 29px;
    }
  }
`;
