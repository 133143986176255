import React from 'react';
import Layout from './src/components/Layout';
// import { MessageProvider } from './src/components/MessageContext';
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

// export function wrapRootElement({ element }) {
//   return <MessageProvider>{element}</MessageProvider>;
// }
