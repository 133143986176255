import styled from 'styled-components';

export const ProjectContainerStyle = styled.div`
  margin-top: 10vh;
  .images {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0px;
    padding-top: ${(props) => (props.posFixed ? 'var(--p)' : '0')};

    div,
    .gatsby-image-wrapper {
      font-size: var(--p-small);
      width: 100%;
      height: fit-content;
      text-align: right;
    }
  }

  .info-texts {
    width: 50%;
    p {
      padding-bottom: 20px;
    }
  }

  .title {
    position: ${(props) => (props.posFixed ? 'fixed' : 'inline')};
    top: ${(props) => `${props.topPosition + 20}px`};
    z-index: 2;
    display: inline-block;
    width: 100%;
    cursor: pointer;
  }

  .title.left {
    right: -20px;
  }

  .title.right {
    left: -20px;
  }

  .title.center {
    right: 0px;
  }

  @media (max-width: 900px) {
    .info-texts {
      width: 100%;
    }
  }
`;
