import { createGlobalStyle } from 'styled-components';

import fontVerily from '../assets/font/VerilySerifMono.otf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: "fontVerily";
    src: url(${fontVerily});
  }


  html {
    font-family: "fontVerily";
    color: var(--blackGreen);
    font-optical-sizing: none;
  }


  a {
    text-decoration: none;
    text-decoration-skip-ink: none;
  }


  ul {
    list-style: none;
  }


  @media (max-width: 900px) {

  }
`;

export default Typography;
