import React, { useEffect, useRef, useState } from 'react';
import 'normalize.css';

import moment from 'moment';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

import SEO from './SEO';
import Navbar from './Navbar';
import ProjectContainer from './ProjectContainer';
import { AppContainerStyle } from '../styles/AppContainerStyle';
import SingleProject from './SingleProject';
import NavMobil from './NavMobil';

export default function Layout({ children, location, pageContext }) {
  // console.log('test', pageContext);
  const appcontainer = useRef(null);
  const prevScrollY = useRef(0);
  const [currentY, setCurrentY] = useState(0);
  const [navOpen, setNavOpen] = useState(false);
  const [desktop, setDesktop] = useState(false);
  let sortedArray;

  const {
    props: {
      data: { projectInfo },
    },
  } = children;

  const {
    props: {
      data: { projectLabo },
    },
  } = children;

  const {
    props: {
      data: { projectStudio },
    },
  } = children;

  const {
    props: {
      data: { queryInfos },
    },
  } = children;

  const {
    props: {
      data: { queryStudio },
    },
  } = children;

  const {
    props: {
      data: { queryLaboratory },
    },
  } = children;

  if (queryStudio && queryLaboratory && queryInfos) {
    queryStudio.nodes.forEach((project) => {
      project.position = 'center';
    });

    queryLaboratory.nodes.forEach((project) => {
      project.position = 'right';
    });

    queryInfos.nodes.forEach((project) => {
      project.position = 'left';
    });
    const nodesCombined = queryInfos.nodes.concat(
      queryLaboratory.nodes,
      queryStudio.nodes
    );
    sortedArray = nodesCombined.sort(
      (a, b) => moment(b._updatedAt).valueOf() - moment(a._updatedAt).valueOf()
    );
  } else if (queryStudio && queryLaboratory) {
    queryStudio.nodes.forEach((project) => {
      project.position = 'center';
    });

    queryLaboratory.nodes.forEach((project) => {
      project.position = 'right';
    });
    const nodesCombined = queryLaboratory.nodes.concat(queryStudio.nodes);
    sortedArray = nodesCombined.sort(
      (a, b) => moment(b._updatedAt).valueOf() - moment(a._updatedAt).valueOf()
    );
  }

  const data = children?.props?.data;

  const onScroll = (e) => {
    const currentScrollY = e.target.scrollTop;
    prevScrollY.current = currentScrollY;
    setCurrentY(currentScrollY);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 900);
    }
  }, []);

  useEffect(() => {
    appcontainer.current.scrollTo(0, 0);
    setNavOpen(false);
  }, [location]);

  return (
    <AppContainerStyle ref={appcontainer} onScroll={onScroll}>
      <SEO
        description={children.props.data?.seo?.description}
        image={children.props.data?.seo?.image?.asset?.fluid?.src}
        title={children.props.data?.seo?.title}
        titlePage={children.props.data?.seo?.titlePage}
      />
      <GlobalStyles />
      <Typography />
      {data && desktop && (
        <Navbar
          data={data}
          pageContext={pageContext}
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          desktop={desktop}
          appcontainer={appcontainer}
        />
      )}

      {data && !desktop && (
        <NavMobil
          data={data}
          pageContext={pageContext}
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          desktop={desktop}
          appcontainer={appcontainer}
        />
      )}

      {sortedArray &&
        sortedArray.map((project, id) => (
          <ProjectContainer
            project={project}
            id={id}
            key={id}
            currentY={currentY}
            pageContext={pageContext}
            desktop={desktop}
          />
        ))}

      {projectInfo && (
        <SingleProject
          project={projectInfo}
          position="left"
          desktop={desktop}
        />
      )}
      {projectLabo && (
        <SingleProject
          project={projectLabo}
          position="right"
          desktop={desktop}
        />
      )}
      {projectStudio && (
        <SingleProject
          project={projectStudio}
          position="center"
          desktop={desktop}
        />
      )}
    </AppContainerStyle>
  );
}
