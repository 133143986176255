import { createGlobalStyle } from 'styled-components';
// import bg from '../assets/images/bg.svg';
// import stripes from '../assets/images/stripes.svg';

const GlobalStyles = createGlobalStyle`
  :root {
  --Weingarten: #FF4C1B;
  --Saal: #001D31;
  --Klub: #AF9767;
  --Biergarten: #8CB8C3;
  --Kornervilla: #FFEF47;
  --Eisdiele:#F4D9D8;
  --Turmhaus: #A7A8B0;
  --blue: #001D31;
  --filtersFont: 2rem;
  --h2: 1.35vw;
  --h3: 1.35vw;
  --h4: 1.4vw;
  --p: 2.25vh;
  --p-small: 1.25vh;
  --grey: #c2c3c7;

  --marginvw: 0.5vw;

  }


  body {
    color: var(--blue);
    scrollbar-color: #cfcfcf white;
    scrollbar-width: thin;
    font-variant-ligatures: none;
   font-size: var(--p);
   overflow: hidden;
   color: #450000;

  }


  .hidden {
    display: none !important;
  }

  p, h1, h2, h3, h4, h5, ul, a {
     font-size:  var(--p);
    padding: 0;
    margin: 0;
    mix-blend-mode: color-burn;
     color: #450000;

  }

  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: smooth;
    margin-bottom: var(--marginvw);
  } */


  /* a {
    color: var(--blue);
  } */
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #ffffff;
}

::-webkit-scrollbar-thumb {
    background: #dcdbdb;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px ;
}

::-webkit-scrollbar-corner {
    background: #b3b3b3;
}

/* .ais-Highlight {
  background-color: blue;
} */

  @media (max-width: 900px) {

  :root {
      --h2: 8vw;
      --h3: 6vw;
      --h4: 4vw;
      --p: 2vh;
       --p-small: 1vh;
      --marginvw: 1rem;
      }

/* .slick-dots {
      display: none !important;
    } */
      p, h1, h2, h3, h4, h5, ul, a {

    mix-blend-mode: inherit;


  }

  }


`;

export default GlobalStyles;
