import React, { useEffect, useRef, useState } from 'react';
import 'normalize.css';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SanityBlockContent from '@sanity/block-content-to-react';
import { ProjectContainerStyle } from '../styles/ProjectContainerStyle';

// import NavbarStyle from '../styles/NavbarStyles';

export default function ProjectContainer({
  project,
  id,
  currentY,
  pageContext,
  desktop,
}) {
  const [posFixed, setPosFixed] = useState(false);
  const projectRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (desktop) {
      if (
        !pageContext.categorie &&
        projectRef.current.getBoundingClientRect().y < (id + 1) * 20
      ) {
        setPosFixed(true);
      } else if (
        pageContext.categorie &&
        projectRef.current.getBoundingClientRect().y < (id + 1) * 40
      ) {
        setPosFixed(true);
      } else {
        setPosFixed(false);
      }
    }

    // setPosFixed(false);

    // return () => {
    //   second
    // }
  }, [currentY, desktop]);

  return (
    <ProjectContainerStyle
      ref={projectRef}
      posFixed={posFixed}
      topPosition={!pageContext.categorie ? (id + 1) * 20 : (id + 1) * 20 + 25}
    >
      <Link
        to={project.slug && `/${project.slug.current}`}
        className={`title${id} ${project.position} title`}
      >
        {project.date && `${project.date}`}
        {project.date && project.project && `, `}
        {project.project && `${project.project}`}
        {project.categories && project.project && `, `}
        {project.categories &&
          !pageContext.categorie &&
          `${project.categories}`}
      </Link>
      {desktop && (
        <Link to={project.slug && `/${project.slug.current}`}>
          <div className="images">
            {project.imageshomepage &&
              project.imageshomepage.map((image) => (
                <div className={`${project.position}`}>
                  <Img fluid={image.asset.fluid} alt={image.alt} />
                  {image.alt}
                </div>
              ))}
          </div>
        </Link>
      )}
      {!desktop && (
        <Slider {...settings}>
          {project.imageshomepage &&
            project.imageshomepage.map((image) => (
              <Img fluid={image.asset.fluid} alt={image.alt} />
            ))}
        </Slider>
      )}
      {project.position === 'left' && project._rawText && (
        <SanityBlockContent
          className={` ${project.position} info-texts `}
          blocks={project._rawText}
          projectId="ft52ooxt"
          dataset="production"
        />
      )}
    </ProjectContainerStyle>
  );
}
