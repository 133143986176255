import React, { useState } from 'react';
import 'normalize.css';
import { Link } from 'gatsby';
import { RxCross1 } from 'react-icons/rx';
import { NavbarStyle } from '../styles/NavbarStyles';

// import NavbarStyle from '../styles/NavbarStyles';

export default function Navbar({
  data,
  pageContext,
  navOpen,
  setNavOpen,
  appcontainer,
  desktop,
}) {
  const [menuOpen, setMenuOpen] = useState('');
  const handleClick = (e) => {
    e.preventDefault();
    setMenuOpen(e.currentTarget.id);
    setNavOpen(!navOpen);
    appcontainer.current.scrollTo(0, 0);
  };

  const handleClickClose = (e) => {
    setNavOpen(false);
    appcontainer.current.scrollTo(0, 0);
  };

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const uniqueArray = (nav) => {
    let categories = [];
    categories = nav.nodes.map((project) => project.categories);
    return (categories = [...new Set(categories)]);
  };

  return (
    <NavbarStyle open={navOpen}>
      <div className="left">
        <p id="info" onClick={(e) => handleClick(e)} className="">
          Anneleen Bertels
        </p>

        {menuOpen === 'info' &&
          navOpen &&
          uniqueArray(data.navInfo).map((title) => (
            <Link
              key={title}
              className=""
              to={`/${slugify(title)}`}
              onClick={(e) => handleClickClose(e)}
            >
              <p>{title}</p>
            </Link>
          ))}
      </div>

      <div className="center">
        <p id="studio" href="#" onClick={(e) => handleClick(e)}>
          Studio
        </p>

        {pageContext.categorie && pageContext.section === 'studio' && !navOpen && (
          <Link className="" to="/">
            <p className="categorie">
              <RxCross1 className="cross" />
              {pageContext.categorie}
            </p>
          </Link>
        )}

        {menuOpen === 'studio' &&
          navOpen &&
          uniqueArray(data.navStudio).map((title) => (
            <Link
              key={title}
              className={
                pageContext.categorie === title ? 'selected-categorie' : ''
              }
              to={`/${slugify(title)}`}
              onClick={(e) => handleClickClose(e)}
            >
              <p>
                {pageContext.categorie === title && (
                  <RxCross1 className="cross" />
                )}
                {title}
              </p>
            </Link>
          ))}
      </div>

      <div className="right">
        <p id="labo" href="#" onClick={(e) => handleClick(e)}>
          Laboratory
        </p>

        {pageContext.categorie && pageContext.section === 'labo' && !navOpen && (
          <Link className="" to="/">
            <p className="categorie">
              <RxCross1 className="cross" />
              {pageContext.categorie}
            </p>
          </Link>
        )}
        {menuOpen === 'labo' &&
          navOpen &&
          uniqueArray(data.navLabo).map((title) => (
            <Link
              key={title}
              className={
                pageContext.categorie === title ? 'selected-categorie' : ''
              }
              to={`/${slugify(title)}`}
              onClick={(e) => handleClickClose(e)}
            >
              <p>
                {pageContext.categorie === title && (
                  <RxCross1 className="cross" />
                )}
                {title}
              </p>
            </Link>
          ))}
      </div>
    </NavbarStyle>
  );
}
