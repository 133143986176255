import styled from 'styled-components';

export const AppContainerStyle = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(90vh - 20px);
  padding: 0 20px;
  padding-top: 20px;
  padding-bottom: 10vh;

  .left {
    text-align: left !important;
  }

  .center {
    text-align: center !important;
    font-family: 'Times New Roman', Times, serif;
  }

  .right {
    text-align: right !important;
    font-family: Helvetica, sans-serif;
  }

  .info {
    align-items: flex-start;
  }

  .labo {
    align-items: flex-end;
  }

  .studio {
    align-items: center;
  }

  @media (max-width: 900px) {
    .right {
      text-align: left !important;
    }

    .center {
      text-align: left !important;
    }

    .labo {
      align-items: flex-start;
    }

    .studio {
      align-items: flex-start;
    }
  }
`;
