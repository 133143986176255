import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({
  children,
  location,
  description,
  title,
  image,
  titlePage,
}) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  return (
    <Helmet titleTemplate={`%s ${site.siteMetadata.title}`}>
      <html lang="en" />
      <title>{title || ' '}</title>
      {/* Fav Icons */}

      <link rel="apple-touch-icon" sizes="180x180" href="/favicon.ico" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.webmanifest" />
      <link rel="alternate icon" href="/favicon.ico" />

      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta
        name="description"
        content='Anneleen Bertels is a Belgian textile creator and founder of her own Textile & Embroidery Lab. The Textile & Embroidery Lab does research on how the development of new (sustainable) materials and innovative technologies interacts and clashes with traditional crafts and techniques. The Lab merges embroidery, jewelry and textile techniques in an artisanal and contemporary way."'
      />

      {/* Open Graph */}
      {/* {location && <meta property="og:url" content={location.href} />} */}

      <meta
        property="og:image"
        content={image || '../assets/favicon_io/android-chrome-192x192.png'}
      />
      <meta
        property="og:title"
        content={titlePage || 'Anneleen Bertels'}
        key="ogtitle"
      />
      {/* <meta
        propery='og:site_name'
        content={site.siteMetadata.title}
        key='ogsitename'
      /> */}
      <meta
        property="og:description"
        content={
          description ||
          'Anneleen Bertels is a Belgian textile creator and founder of her own Textile & Embroidery Lab. The Textile & Embroidery Lab does research on how the development of new (sustainable) materials and innovative technologies interacts and clashes with traditional crafts and techniques. The Lab merges embroidery, jewelry and textile techniques in an artisanal and contemporary way."'
        }
        key="ogdesc"
      />
      {children}
    </Helmet>
  );
}
